const config = {
  WEBAPP_HOST: process.env.PREACT_APP_WEBAPP_HOST,
  WEBAPP_HOST_GREENFACTORY:
    process.env.PREACT_APP_ENV !== 'local'
      ? process.env.PREACT_APP_WEBAPP_HOST_GREENFACTORY
      : process.env.PREACT_APP_WEBAPP_HOST,
  API_HOST: process.env.PREACT_APP_API_HOST,
  API_HOST_GREENFACTORY:
    process.env.PREACT_APP_ENV !== 'local'
      ? process.env.PREACT_APP_API_HOST_GREENFACTORY
      : process.env.PREACT_APP_API_HOST,
  GTAG_ID: process.env.PREACT_APP_GTAG_ID,
};

module.exports = config;
