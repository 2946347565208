import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Iconify from '../../components/Iconify';
import { SubHeadline } from '../../components/SubHeadline';

const data = [
  {
    title: 'Übermitteln Sie Ihren Fahrzeugschein und Ihre Kontaktdaten.',
    icon: 'mdi:file-send-outline',
  },
  {
    title: 'Bestätigen Sie Ihre Email-Adresse.',
    icon: 'material-symbols:mark-email-read-outline-rounded',
  },
  {
    title: 'Auszahlung der THG-Prämie in 1 - 5 Werktagen direkt auf Ihr Konto.',
    icon: 'humbleicons:money',
  },
];
export const StepsExplain = () => {
  const theme = useTheme();

  return (
    <Box>
      <SubHeadline text={'So einfach geht die Beantragung ihrer THG-Prämie'} />

      <Grid container spacing={2}>
        {data.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box width={1} height={1}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Iconify
                  icon={item.icon}
                  style={{
                    fontSize: '48px',
                    color: theme.palette.primary.main,
                    marginBottom: '16px',
                  }}
                />
                <Typography
                  gutterBottom
                  fontWeight={500}
                  align={'center'}
                  sx={{ fontSize: { xs: '16px', md: '16px' } }}
                >
                  {item.title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
